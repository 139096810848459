import React from 'react';

import * as Sentry from '@sentry/react';
import config from 'config';
import ReactDOM from 'react-dom/client';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (config.TRACES_SAMPLE_RATE > 0) {
  Sentry.init({
    environment: config.ENVIRONMENT,
    dsn: config.SENTRY_DSN,
    tracesSampleRate: config.TRACES_SAMPLE_RATE,
    replaysSessionSampleRate: config.TRACES_SAMPLE_RATE,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.BrowserTracing({ tracePropagationTargets: ['*'] }), new Sentry.Replay()],
    release: config.DOCKER_IMAGE_TAG,
  });
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
