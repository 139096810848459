import React, { useState } from 'react';

import {
  ActionIcon,
  Button,
  createStyles,
  Text,
  Group,
  Navbar,
  Title,
  Box,
  useMantineTheme,
  Avatar as MantineAvatar,
  Container,
  Divider,
} from '@mantine/core';
import { IconChevronLeft, IconX } from '@tabler/icons-react';
import { ReactComponent as KiloLogo } from 'assets/svg/long_kilo_blue_white.svg';
import { useAuth } from 'context/auth-context';
import useAthleteLinkedProfiles from 'hooks/athlete/useAthleteLinkedProfiles';
import useImpersonation from 'hooks/kiloStaff/useImpersonate';
import useCurrentRoute from 'hooks/useCurrentRoute';
import { use100vh } from 'react-div-100vh';
import { useLocation, useNavigate } from 'react-router-dom';
import notificationHandler from 'utils/notifications';

const routeTitles: Record<string, string> = {
  '/classes/:eventId': 'Class Details',
  '/documents/:documentId': 'Document',
  '/appointments/:eventId': 'Appointment Details',
};

const useStyles = createStyles(theme => ({
  root: {
    width: '100%',
    background: theme.white,
    padding: theme.spacing.md,
    boxShadow: theme.shadows.xs,
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    alignItems: 'center',
    gap: theme.spacing.lg,
  },
  navbar: {
    maxWidth: theme.breakpoints.sm,
    height: '100%',
    position: 'absolute',
    zIndex: 10,
  },
  iconx: {
    cursor: 'pointer',
    marginLeft: '10px',
  },
}));

const Header = (): JSX.Element => {
  const { classes } = useStyles();
  const { state } = useLocation();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const pathname = useCurrentRoute();
  const { errorNotification } = notificationHandler();
  const { impersonateUser, stopImpersonating, getImpersonatorToken, isLinkedAccountImpersonation } =
    useImpersonation();
  const { logout, user } = useAuth();
  const [isSubaccHidden, setIsSubaccHidden] = useState<boolean>(true);
  const height = use100vh();
  const linkedProfiles = useAthleteLinkedProfiles(user.userId);

  const handleBackClick = (): void => {
    if (state && state.prevPath) {
      navigate(state.prevPath, { state: state });
    } else {
      navigate(-1);
    }
  };

  const handleStopImpersonating = (): void => {
    stopImpersonating();
  };

  const handleImpersonateLinkedAthlete = (userId: string): void => {
    if (getImpersonatorToken()) {
      errorNotification('Cannot impersonate while impersonating');
      return;
    }
    impersonateUser(userId);
  };

  const hasRouteTitle = !!routeTitles[pathname];

  const isAccountPage = pathname === '/account';

  return (
    <>
      <div className={classes.root}>
        {hasRouteTitle ? (
          <div className={classes.gridContainer}>
            <ActionIcon variant='light' radius='lg' size='xl' onClick={handleBackClick}>
              <IconChevronLeft size='1.625rem' />
            </ActionIcon>
            <Title order={3} color='gray.8' align='center'>
              {routeTitles[pathname]}
            </Title>
            <div />
          </div>
        ) : (
          <Group position={isAccountPage ? 'apart' : 'center'}>
            <KiloLogo height='100%' width={75} />
            <Group>
              {isAccountPage && user.isAthlete && !user.primaryUser && user.isLinkedAccount && (
                <Button color='kiloblue' radius='lg' onClick={() => setIsSubaccHidden(!isSubaccHidden)}>
                  View Sub-accounts
                </Button>
              )}
              {isAccountPage && !getImpersonatorToken() && (
                <Button color='red' radius='lg' onClick={logout}>
                  Log Out
                </Button>
              )}
              {isAccountPage && getImpersonatorToken() && (
                <Button color='red' radius='lg' onClick={handleStopImpersonating}>
                  {isLinkedAccountImpersonation() ? 'Return to My Account' : 'Stop Impersonating'}
                </Button>
              )}
            </Group>
          </Group>
        )}
      </div>
      <Navbar
        hidden={isSubaccHidden}
        height={height || undefined}
        position={{ top: 0, left: 0 }}
        className={classes.navbar}
        sx={{ visibility: isSubaccHidden ? 'hidden' : 'visible' }}
      >
        <div style={{ height: '50px', display: 'flex', alignItems: 'center' }}>
          <IconX size={30} onClick={() => setIsSubaccHidden(!isSubaccHidden)} className={classes.iconx} />
          <Text sx={{ margin: 'auto', fontWeight: 'bold' }} size={theme.fontSizes.md}>
            Select Account
          </Text>
        </div>
        <Container bg='gray.4' sx={{ width: '100%', height: '30px', display: 'flex', alignItems: 'center' }}>
          <Text size={theme.fontSizes.md} ml='1em' tt='uppercase'>
            Primary account
          </Text>
        </Container>
        <Group sx={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}>
          <Box sx={{ paddingLeft: '1em' }}>
            <MantineAvatar
              src={user.profilePicture}
              variant='outline'
              color='kiloBlue'
              radius='100%'
              size={50}
            >
              {user.fullName}
            </MantineAvatar>
          </Box>
          <Box>
            <Text>{user.fullName}</Text>
            <Text>{user.email}</Text>
          </Box>
        </Group>
        <Container
          bg='gray.4'
          sx={{ width: '100%', height: '30px', display: 'flex', alignItems: 'center', marginLeft: '0' }}
        >
          <Text size={theme.fontSizes.md} ml='1em' tt='uppercase'>
            Linked accounts
          </Text>
        </Container>
        <Box sx={{ overflowY: 'auto' }}>
          {linkedProfiles.data?.data
            .filter(linkedAthlete => linkedAthlete.primaryUser)
            .map(linkedAthlete => {
              return (
                <>
                  <Group
                    key={`${linkedAthlete.userId}`}
                    sx={{ paddingTop: '0.5em', paddingBottom: '0.5em', cursor: 'pointer' }}
                    onClick={() => handleImpersonateLinkedAthlete(linkedAthlete.userId)}
                  >
                    <Box sx={{ paddingLeft: '1em' }}>
                      <MantineAvatar
                        src={linkedAthlete.profilePicture}
                        variant='outline'
                        color='kiloBlue'
                        radius='100%'
                        size={50}
                      >
                        {linkedAthlete.fullName}
                      </MantineAvatar>
                    </Box>
                    <Box>
                      <Text>{linkedAthlete.fullName}</Text>
                      <Text>{linkedAthlete.email}</Text>
                    </Box>
                  </Group>
                  <Divider />
                </>
              );
            })}
        </Box>
      </Navbar>
    </>
  );
};

export default Header;
