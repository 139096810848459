import { useClient } from 'context/auth-context';
import { useQuery, UseQueryResult } from 'react-query';
import { FlagData } from 'types';

function useFlags(isAuthenticated?: boolean): UseQueryResult<{ data: FlagData }> {
  const client = useClient();

  const enabled = isAuthenticated !== undefined && isAuthenticated == false ? false : true;

  return useQuery('flags', () => client.get(`flags/`), {
    enabled: enabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}

export default useFlags;
