import React from 'react';

import { useAbility } from '@casl/react';
import { Notifications } from '@mantine/notifications';
import { AbilityContext } from 'Can';
import ErrorBoundary from 'components/ErrorBoundary';
import { useAuth } from 'context/auth-context';
import useFlags from 'hooks/flags/useFlags';
import useGym from 'hooks/gym/useGym';
import Error from 'pages/shared/Error';
import Loading from 'pages/shared/Loading';
import { getRouteMapList } from 'routes/index';
import Routes from 'routes/render-routes';
import { flagActive } from 'utils/flags';

const Router = (): JSX.Element => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const isKiosk = localStorage.getItem('isKiosk') === 'true';
  const gymHook = useGym(user?.gym);
  // TODO: Fix abilities. This should accept a user and provide a global context
  const ability = useAbility(AbilityContext);

  const { data: flagData, isSuccess: flagDataSuccess } = useFlags(isAuthenticated);
  const isFlowsFlagEnabled =
    flagData && flagDataSuccess && flagActive(flagData.data, 'use_notification_builder');

  const routeMapList = getRouteMapList(
    user,
    isLoading,
    isAuthenticated,
    isKiosk,
    gymHook,
    ability,
    isFlowsFlagEnabled,
  );

  return (
    <ErrorBoundary errorMessage={<Error />}>
      <Notifications
        data-cy='notificationsContainer'
        position={user?.isAthlete ? 'top-center' : 'bottom-left'}
      />
      <React.Suspense fallback={<Loading />}>
        <Routes routeMapList={routeMapList} />
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default Router;
