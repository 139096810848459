import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

interface IProps {
  errorMessage: React.ReactNode;
  shouldRedirect?: boolean;
  children: React.ReactNode;
}
interface IState {
  hasError: boolean;
}

const ErrorBoundaryInner = ({
  children,
  hasError,
  reset,
  shouldRedirect,
}: {
  hasError: boolean;
  shouldRedirect?: boolean;
  reset: () => void;
  children: React.ReactNode;
}): JSX.Element => {
  const navigate = useNavigate();
  const isProduction = process.env.REACT_APP_STAGE === 'production';

  useEffect((): (() => void) | undefined => {
    if (isProduction) {
      if (hasError && shouldRedirect) {
        const timer = setTimeout(() => {
          navigate('/', { replace: true });
          reset();
        }, 5000);
        return () => clearTimeout(timer);
      }
      return undefined;
    }
    return undefined;
  }, [hasError, shouldRedirect, reset, isProduction, navigate]);

  return <>{children}</>;
};

export default class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  public componentDidCatch(): void {
    this.setState({ hasError: true });
  }

  private reset = (): void => this.setState({ hasError: false });

  public triggerError = (): void => {
    this.setState({ hasError: true });
  };

  public render(): JSX.Element {
    const shouldShowError = this.state.hasError;
    return (
      <ErrorBoundaryInner
        reset={this.reset}
        hasError={this.state.hasError}
        shouldRedirect={this.props.shouldRedirect || false}
      >
        {shouldShowError ? this.props.errorMessage : this.props.children}
      </ErrorBoundaryInner>
    );
  }
}
