import { SelectItem } from '@mantine/core';
import dayjs from 'dayjs';
import { UrlParams } from 'types';

export const isEmpty = (text: any): boolean =>
  text === null ||
  text === undefined ||
  (typeof text === 'string' &&
    (text === '' || text.replace(/<br ?\/?>/gi, '').replace(/<\/?p>/gi, '') === '')) ||
  (Array.isArray(text) && text.length === 0);

export function urlParamsFromUrl(url: string): UrlParams {
  const urlObj = new URL(url);
  const entries = new URLSearchParams(urlObj.search).entries();
  const result: UrlParams = {};
  let hasNext = true;
  while (hasNext) {
    const { value, done } = entries.next();
    if (!done) {
      const [k, v] = value;
      result[k] = v;
    }
    hasNext = !done;
  }
  return result;
}

export const encodeUrlParams = (query: UrlParams): string =>
  Object.entries(query)
    .map(kv => kv.map(encodeURIComponent).join('='))
    .join('&');

export const sortArray = (
  data: any[],
  { orderBy, reversed }: { orderBy: string; reversed: boolean },
): any[] => {
  if (!orderBy) return data;

  return data.sort((prevRow, currentRow) => {
    let prevValue = prevRow[orderBy];
    let currentValue = currentRow[orderBy];

    if (dayjs(prevValue).isValid()) {
      prevValue = dayjs(prevValue).format('YYYYMMDDHHmm').toString();
    }

    if (dayjs(currentValue).isValid()) {
      currentValue = dayjs(currentValue).format('YYYYMMDDHHmm').toString();
    }

    if (typeof prevValue === 'string') {
      return reversed ? currentValue.localeCompare(prevValue) : prevValue.localeCompare(currentValue);
    }
    if (typeof prevValue === 'number') {
      return reversed ? currentValue - prevValue : prevValue - currentValue;
    }
    if (typeof prevValue === 'boolean') {
      if (prevValue === currentValue) return 0;
      if (prevValue) return reversed ? 1 : -1;
      return reversed ? -1 : 1;
    }
    return 0;
  });
};

export const removeEmpty = (obj: object): object =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

export const getFilteredMultiSelectPrevState = (
  prevState: SelectItem[],
  fetchedData: SelectItem[],
  value: string[],
): SelectItem[] =>
  prevState.filter(p => value?.includes(p.value) && !fetchedData.some(i => i.value === p.value));

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      if (event.target && event.target.result) {
        const base64String = event.target.result.toString().split(',')[1];
        resolve(base64String);
      } else {
        reject(new Error('Failed to read the file.'));
      }
    };
    reader.readAsDataURL(file);
  });
}

// bankers' rounding (a.k.a round half to even), when fraction is at 0.5 it will round to the nearest even number
// bankersRound(2.675) == 2.68
// bankersRound(2.685) == 2.68
export const bankersRound = (n: number, d = 2): number => {
  const x = n * Math.pow(10, d);
  const r = Math.round(x);
  const br = Math.abs(x) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
  return br / Math.pow(10, d);
};
