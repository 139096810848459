import { FlagData } from 'types';

const isActive = (key: string, data: [[string, boolean]]): boolean => {
  for (let i = 0; i < data.length; i += 1) {
    const elt = data[i];
    if (elt[0] === key && elt[1]) {
      return true;
    }
  }
  return false;
};

export const flagActive = (flagData: FlagData, key: string): boolean => {
  return isActive(key, flagData.flags);
};
